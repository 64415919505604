import React from "react";
const Hero = () => (
  <div className="text-center hero my-5">
    <p className="lead">
      I'm Daeloth, a programmer and 3D modeler, currently making content for VRChat. Feel free to browse some of my content by clicking below.
    </p>
  </div>
);

export default Hero;
