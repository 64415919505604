import { Component } from "react";
import {buttons, commission} from "../utils/mainButtons";
import { ButtonBases } from "./Buttons";

class Content extends Component {
  render() {
    return ButtonBases(buttons, "50%");
  }
}
class Commission extends Component{
  render() {
    return ButtonBases(commission, "100%");
  }
}

export {Content, Commission};
