import React, { Fragment } from "react";
import {Text} from 'react-native';
export const Coding = () => {
  return (
    <Fragment>
      <h2 className="header">Contact</h2>
      <hr />
      <Text className="text-left hero my-5 lead">{`
          
          Discord: Daeloth#0420

          Not much more here, add me on Discord if you wanna chat
    `}</Text>
      <hr />
    </Fragment>
  )
};
export default Coding;