import React, { Fragment } from "react";
import ModelsComponent from "../components/Models";
export const Models = () => {
  return (
    <Fragment>
      <h2 className="header">Models</h2>
      <hr />
      <ModelsComponent />
      <hr />
    </Fragment>
  )
};
export default Models;