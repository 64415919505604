import React, { Fragment } from "react";
import CodingComponent from "../components/Coding";
export const Coding = () => {
  return (
    <Fragment>
      <h2 className="header">Coding</h2>
      <hr />
      <CodingComponent />
      <hr />
    </Fragment>
  )
};
export default Coding;