import React from "react";
import {NavLink} from "react-router-dom";

import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import ButtonBase from "@mui/material/ButtonBase";
import Typography from "@mui/material/Typography";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const ImageButton = styled(ButtonBase)(({ theme }) => ({
    position: "relative",
    height: 200,
    textShadow: '2px 2px 2.5px rgba(0,0,0,1)',
    [theme.breakpoints.down("sm")]: {
      width: "100% !important", // Overrides inline-style
      height: 100
    },
    ".desc":{
      opacity:0
    },
    "&:hover, &.Mui-focusVisible, & .desc": {
      zIndex: 1,
      "& .title": {
        transform: 'translate(0, -75%)',
        transition: '0.5s ease'
      },
      "& .desc": {
        transform: 'translate(0, 50%)',
        opacity: 1,
        transition: '0.5s ease'
  
      },
      "& .buttonimg":{
        filter: 'blur(4px)',
        WebkitFilter: 'blur(4px)',
        transition: '0.5s ease'
      }
    }
  }));
  const ImageSrc = styled("span")({
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundSize: "cover",
    backgroundPosition: "center 40%",
  
  });
  const Image = styled("span")(({ theme }) => ({
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: theme.palette.common.white,
  }));
  const ImageBackdrop = styled("span")(({ theme }) => ({
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundColor: theme.palette.common.black,
    opacity: 0.3,
    transition: theme.transitions.create("opacity")
  }));
  
  export function ButtonBases(buttons, width, external) {
    return (
      <Box sx={{ display: 'flex', flexWrap: 'wrap', minWidth: 300, width: '100%' }}>
        {buttons.map((col, i) => (
          <ImageButton
            focusRipple
            key={col.title}
            style={{
              width: width,
            }}
          >

              <ImageSrc className="buttonimg" style={{ backgroundImage: `url(${col.imgurl})`, backgroundPosition: col.align}} />
                <NavLink
                    to={{pathname: col.link}} target={external||col.external?"_blank":"_self"}
                    >
                
                <ImageBackdrop className="MuiImageBackdrop-root" />
                <Image>
                <Typography
                    component="span"
                    className="title"
                    variant="subtitle1"
                    color="white"
                    sx={{
                    position: 'relative',
                    p: 4,
                    pt: 2,
                    pb: (theme) => `calc(${theme.spacing(1)})`,
                    }}
                >
                    {col.title} {col.external?<FontAwesomeIcon icon="external-link-alt" />:""}
                </Typography>
                </Image>
                </NavLink>
                <Typography className="desc"
                            color="white"
                            component="span"
                            variant="subtitle3"
                            sx={{pointerEvents: "none"}}
                            >
                {col.description}
                </Typography>
            
          </ImageButton>
        ))}
      </Box>
      
    );
  }