import React from "react";
import logo from "../assets/ralsei.png";
import { Link } from "react-router-dom";
import {
  Container
} from "reactstrap";

const Footer = () => (
  
  <footer className="bg-dark p-3 text-center">

    <Container>
      <img className="logoa mr-auto" alt="Logo" src={logo} />
      <Link color="primary" className="btn-primary btn btn-primary" style={{position:"absolute", marginTop:25, marginLeft:"20%"}}
      to="/contact">
      Contact
    </Link>
      <p>
        © Daeloth 2023
      </p>

    </Container>

  </footer>
);


export default Footer;
