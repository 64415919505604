const buttons = [
  {
    title: "3D models",
    link: "/models",
    description:
      "Humanoid models and props made in Blender, mostly used for modding or importing into other engines and games",
    imgurl: "./models.png"
  },
  {
    title: "Coding",
    link: "/coding",
    description:
      "Some web dev and TS projects",
    imgurl: "./coding.jpg"
  },
  {
    title: "Mods",
    link: "https://gamebanana.com/members/1608104",
    description:
      "Content modifications for various games",
    imgurl: "./mods.jpg",
    external: true
  },
  {
    title: "Music",
    link: "https://www.youtube.com/channel/UCgUA2HZhv0PXWgdKC6eah5w",
    description:
      "Mashups and guitar covers",
    imgurl: "./music.png",
    external: true
  }

];
const commission = [
  {
    title: "Commission",
    link: "/contact",
    description:
      "Contact me on Discord for questions about comissioning",
    imgurl: "./models.png"
  }
]

export {buttons, commission};
