import { Component } from "react";
import buttons from "../utils/codingButtons";
import { ButtonBases } from "./Buttons";

class Coding extends Component {
  render() {
    return ButtonBases(buttons, "100%", true);
  }
}

export default Coding;
