import React, { Fragment } from "react";

import Hero from "../components/Hero";
import {Content, Commission} from "../components/Content";

const Home = () => (
  <Fragment>
    <Hero />
    <hr />
    <Content />
    <Commission />
    <hr />
  </Fragment>
);

export default Home;
