const buttons = [
  {
    title: "Iso",
    link: "https://daeloth.com/iso",
    description:
      "Isometric game demo, made in Unity",
    imgurl: "../daeloth.png"
  },
  {
    title: "Manguera",
    link: "https://daeloth.com/manguera",
    description:
      "Web dev demo made in Ionic",
    imgurl: "../manguera.png"
  },
  {
    title: "Pato",
    link: "https://daeloth.com/pato",
    description:
      "Fill your screen with ducks! Made in JavaScript",
    imgurl: "../pato.png"
  },
  {
    title: "Flow",
    link: "https://daeloth.com/flow",
    description:
      "Cellular automata, flows down with 4 total values per cell. Made in JavaScript",
    imgurl: "../flow.png"
  },
  {
    title: "Daeloth.com",
    link: "/",
    description:
      "This very website, made in React",
    imgurl: "../daeloth.png",
    align: "left"
  },

];

export default buttons;
